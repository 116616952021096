import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSidenavModule } from '@angular/material/sidenav';
import { Cloudinary } from '@cloudinary/url-gen/instance/Cloudinary';
import { CloudinaryModule } from '@cloudinary/ng';
import { NgIdleModule } from '@ng-idle/core';

import { OAuthModule, OAuthModuleConfig } from 'angular-oauth2-oidc';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { firstValueFrom } from 'rxjs';

import { AppRoutingModule } from 'src/app/app-routing.module';
import { AppComponent } from 'src/app/app.component';
import { CoreModule } from 'src/app/modules/core/core.module';
import { SettingsService } from 'src/app/modules/core/services/settings.service';
import { NgrxStoreModule } from 'src/app/modules/ngrx-store/ngrx-store.module';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { AuthInterceptor } from 'src/app/modules/shared/utilities/auth-interceptor.utils';
import { QuillModule } from 'ngx-quill';

const initSettings = (settingsService: SettingsService, oAuthConfig: OAuthModuleConfig, cloudinary: Cloudinary) => async () => {
	const settings = await firstValueFrom(settingsService.getSettings());
 
	// Adds the apiUrl defined in the environment to the OAuthModuleConfig
	// which will automatically handle adding an auth header to any request made to that url

	oAuthConfig.resourceServer.allowedUrls?.push(settings.apiUrl);

	cloudinary.setConfig({
		cloud: {
			cloudName: settings.cloudinaryCloudName
		}
	});
	return settings;
}

const authConfigFactory = () => ({
	resourceServer: {
		allowedUrls: [],
		sendAccessToken: true,
	}
})

const cloudinaryConfigFactory = () => (
	new Cloudinary()
)

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		AppRoutingModule,
		BrowserAnimationsModule,
		BrowserModule,
		CoreModule,
		HttpClientModule,
		MatSidenavModule,
		NgrxStoreModule,
		OAuthModule.forRoot(),
		SharedModule,
		CloudinaryModule,
		NgIdleModule.forRoot(),
		QuillModule.forRoot()
	],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: initSettings,
			deps: [SettingsService, OAuthModuleConfig, Cloudinary],
			multi: true
		},
		{
			provide: OAuthModuleConfig,
			useFactory: authConfigFactory,
		},
		{
			provide: Cloudinary,
			useFactory: cloudinaryConfigFactory
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true
		},
		provideEnvironmentNgxMask({ validation: false })
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
